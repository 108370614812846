import React from 'react';
// eslint-disable-next-line
import ReactDOM from 'react-dom';
// eslint-disable-next-line
import ReactDOMClient from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
// eslint-disable-next-line
import {BigNumber, Contract, ethers, utils} from "ethers"
import StakingABI from './staking.abi.mjs'
import QrCreator from "qr-creator";

const message = "Remember, Remember the 5th of November | Serenity @ Outer Heaven | https://serenity.outerheaven.org"

declare global{
    interface Window {
        ethereum: any
    }
    interface document {
        querySelector: any
    }
}

const staking = {
    // address: "0xEc8A732E7B2F3DE11F9a7e4633f4C9937AC45383",  // xdai
    // address: "0x382776C06F19723f12D97AdaF481cdaCD350a45a",  // mainnet
    address: "serenity.outerheaven.eth", // mainnet ens
    abi: [
        "function getStatus(address _address)",
    ],
};

// eslint-disable-next-line
class App extends React.Component {
    // eslint-disable-next-line
    constructor(props: any) {
        super(props);
    }
    async componentDidMount() {
    }
    render() {
        return (
            <>
            </>
        );
    }
}

let ethereum = undefined as any
let signer = undefined as any
let address = undefined as any
// eslint-disable-next-line
let balance = undefined as any
let stakingContract = undefined as any
let stakeAmount = undefined as any
let alcoholAmount = undefined as any
let staked = undefined as any
let alcohol = undefined as any
let refunded = undefined as any

async function init() {

    const url = document.URL.substring(document.URL.lastIndexOf("/"))
    console.log(`url: ${url}`)
    if (!window.ethereum && url === "/priority-access.html") {
        const div = document.getElementById('stake-error-div') as HTMLElement
        div.style.display = ""
        div.innerText = "No Ethereum wallet provider detected"
        let stakebtn = document.getElementById('stake-btn') as any
        stakebtn.disabled = true
        return
    } else if (!window.ethereum) {
        return
    }

    ethereum = new ethers.providers.Web3Provider(window.ethereum, 'homestead')
    stakingContract = new ethers.Contract(staking.address, StakingABI, ethereum)

    ethereum.on('accountsChanged', (args: any)=>{console.log(`account changed: ${args}`)})
    ethereum.on('chainChanged', (args: any)=>{console.log(`chain changed: ${args}`)})
    ethereum.on('networkChanged', (args: any)=>{console.log(`network changed: ${args}`)})
    
    const lastBlock = await ethereum.getBlockNumber()
    const lastGas = await ethereum.getGasPrice()
    console.log(`current block: ${lastBlock} with gas price: ${lastGas}`)

    stakeAmount = await stakingContract.stakeAmount()
    alcoholAmount = await stakingContract.alcoholAmount()
    console.log(`stake: ${stakeAmount}, alcohol: ${alcoholAmount}`)
    
    try{
        await ethereum.send("eth_requestAccounts", [])
        signer = ethereum.getSigner()
        address = await signer.getAddress()
        balance = await ethereum.getBalance(address)
        let status = await stakingContract.getStatus(address)
        staked = status[0]
        alcohol = status[1]
        refunded = status[2]
        console.log(`status: ${staked}, ${alcohol}, ${refunded}`)
    } catch (e) {
        console.error(`error requesting accounts: ${JSON.stringify(e)}`)
    }

    if (url === "/" && staked > 0) {
        const button = document.getElementById('priority-access-btn') as HTMLElement
        button.innerText = "View QR Code"
        // @ts-ignore
        button.href = "staked.html"

    } else if (url === "/staked.html") {
        let signature = "" as any
        try {
            signature = await signer.signMessage(message)
            console.log(`signature: ${signature}`)
        } catch (e) {
            console.log(`exception signing message: ${JSON.stringify(e)}`)
        }

        QrCreator.render({
            text: `${signature},${address}`,
            radius: 0, // 0.0 to 0.5
            ecLevel: 'H', // L, M, Q, H
            fill: '#FFFFFF', // foreground color
            background: null, // color or null for transparent
            size: 256 // in pixels
        }, document.querySelector('#qr-code')!);
    }
}

async function stake() {
    let addon = false
    let stakebtn = document.getElementById('stake-btn') as any
    let addonbtn = document.getElementById('alcohol-addon-btn') as any
    let totalAmount = undefined as any

    try {
        stakebtn.innerText = "Confirming..."
        stakebtn.disabled = true

        if (addonbtn.checked){
            addon = true
            totalAmount = BigNumber.from(stakeAmount).add(BigNumber.from(alcoholAmount))
        } else {
            totalAmount = BigNumber.from(stakeAmount)
        }

        stakingContract = stakingContract.connect(signer)
        let stakeTx = await stakingContract.stake(addon, {value: totalAmount.sub(BigNumber.from(alcohol))})
        console.log(`stake tx: ${JSON.stringify(stakeTx)}`)
        await stakeTx.wait(1)

        // send user to staked page
        window.location.replace("/staked.html")
    } catch (e) {
        const div = document.getElementById('stake-error-div') as HTMLElement
        div.style.display = ""
        console.log(`exception calling stake: ${JSON.stringify(e)}`)
    } finally {
        stakebtn.innerText = "Stake"
        stakebtn.disabled = false
    }
}

async function unstake() {
    let unstakebtn = document.getElementById('unstake-btn') as any
    try {
        unstakebtn.innerText = "Confirming..."
        unstakebtn.disabled = true
        stakingContract = stakingContract.connect(signer)
        let unstakeTx = await stakingContract.unstake()
        console.log(`unstake tx: ${JSON.stringify(unstakeTx)}`)
        await unstakeTx.wait(1)

        // send user to home page
        window.location.replace("/")
    } catch (e) {
        console.log(`exception calling unstake: ${JSON.stringify(e)}`)
    } finally {
        unstakebtn.innerText = "Unstake"
        unstakebtn.disabled = false
    }
}

(async () => {
    try {
        await init()
        // @ts-ignore
        window.stake = stake
        // @ts-ignore
        window.unstake = unstake
        // await unstake()
    } catch (e) {
        // @ts-ignore
        console.error(`error running init: ${JSON.stringify(e)}`)
    }
})()

// const root = ReactDOMClient.createRoot(document.getElementById('root') as HTMLElement)
// root.render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

